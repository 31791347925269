import React from 'react'
import Layout from '../components/layout'
import ReviewsSummary from '../components/editorreviews' 
import Seo from '../components/seo' 

const WebHostsReviewPage = ({ data }) => (  
  <Layout> 
    <Seo
      title={'Web Hosting Reviews and Ratings. Compare web hosting providers '}
      description={
        'Independent web hosting reviews and ratings.  Reviews done by our staff as well as by real customers.  Review created by our staff include factors like customer satisfaction, reliability, uptime, tech support and affordability. Read our  reviews before you decide your web hosting company. Use our host comparison tool to find best hosting companies'
      }
    />

    <center>
      <h1>Web hosting Reviews and ratings</h1>
      </center>
      <p>
      Listed below are web hosting reviews and ratings from findbestwebhosting.com as well as from customers themselves. Reviews done by us has been done after careful study and research. Factors include customer satisfaction, reliability, uptime, tech support and affordability. These reviews and consumer ratings may help you choose your web hosting company.
      </p>
   
    <ReviewsSummary   />
  
  </Layout>
)

export default WebHostsReviewPage
