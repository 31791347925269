import React from 'react'
//import parse from 'html-react-parser'
import { Link } from 'gatsby'
//import { EditorReviewList } from './hooks/editorreviews'

const ReviewsSummary = props => {
    //const { reviewslist } = EditorReviewList()
    //console.log('reviewslist=', reviewslist)

/*     let ReviewMenuItems = [

        {
            link: "https://www.findbestwebhosting.com/web-hosting-blog/index.php/interserver-review", anchortext: "InterServer Review"
        },
        {
            link: "/bluehost-review",
            anchortext: "BlueHost Review"
        },
        {
            link: "/siteground-review",
            anchortext: "Siteground review"
        },

        {
            link: "/inmotion-hosting-inc-review",
            anchortext: "Inmotion hosting review"
        },

        {
            link: "/webhostinghub-review",
            anchortext: "Webhostinghub review"
        },
        {
            link: "https://www.findbestwebhosting.com/web-hosting-blog/index.php/discountasp-review", anchortext: "Discountasp.net Review"
        },

        {
            link: "/myhosting-review",
            anchortext: "MyHosting Review"
        },

        {
            link: "/winhost-review",
            anchortext: "WinHost Review"
        },

        {
            link: "/hostmonster-review",
            anchortext: "Hostmonster Review"
        },


        {
            link: "https://www.findbestwebhosting.com/web-hosting-blog/index.php/cloudways-managed-hosting-review",
            anchortext: "Cloudways Review"
        },

        {
            link: "/hostgator-review",
            anchortext: "Hostgator Review"
        },


        {
            link: "/hostmonster-review",
            anchortext: "Hostmonster Review"
        },


        {
            link: "/godaddy-review",
            anchortext: "Godaddy  Review"
        },

        {
            link: "/ipage-review",
            anchortext: "iPage Review"
        },

        {
            link: "/greengeeks-review",
            anchortext: "Greengeeks Review"
        },

        {
            link: "https://www.findbestwebhosting.com/web-hosting-blog/index.php/hostinger-review", anchortext: "Hostinger Review"
        },

        {
            link: "https://www.findbestwebhosting.com/web-hosting-blog/index.php/a2-hosting-detailed-review-speed-hosting-and-more",
            anchortext: "A2 Hosting Review"
        },

        {
            link: "https://www.findbestwebhosting.com/web-hosting-blog/index.php/pressidium-review-managed-wordpress-hosting",
            anchortext: "Pressidium Review"
        },

        {
            link: "/eleven2-review",
            anchortext: "Eleven2  Review"
        },

        {
            link: "/site5-hosting-review",
            anchortext: "Site5 Review"
        },
        {
            link: "/fatcow-review",
            anchortext: "Fatcow  Review"
        },

        {
            link: "/hostpapa-review",
            anchortext: "Hostpapa Review"
        },

        {
            link: "/justhost-review",
            anchortext: "JustHost Review"
        },

        {
            link: "/web-hosting-uk-review",
            anchortext: "Web Hosting UK Review"
        },

        {
            link: "/eboundhost-review",
            anchortext: "eBoundHost Review"
        },

        {
            link: "/ix-web-hosting-review",
            anchortext: "IX Web Hosting Review"
        },

        {
            link: "/applied-innovations-review",
            anchortext: "Applied Innovations Review"
        },

        {
            link: "/server-intellect-review",
            anchortext: "Server Intellect Review"
        },



    ]; */

    let ReviewMenuItems = [

        {
            link: "https://www.findbestwebhosting.com/web-hosting-blog/index.php/interserver-review",
            anchortext: "InterServer Review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 5
        },

        {
            link: "/bluehost-review",
            anchortext: "BlueHost Review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 5
        },

        {
            link: "/siteground-review",
            anchortext: "Siteground review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 5
        },


        {
            link: "/inmotion-hosting-inc-review",
            anchortext: "Inmotion hosting review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 4
        },


        {
            link: "/webhostinghub-review",
            anchortext: "Webhostinghub review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 5
        },

        {
            link: "https://www.findbestwebhosting.com/web-hosting-blog/index.php/discountasp-review", anchortext: "Discountasp.net Review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 5
        },


        {
            link: "/myhosting-review",
            anchortext: "MyHosting Review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 5
        },


        {
            link: "/winhost-review",
            anchortext: "WinHost Review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 5
        },


        {
            link: "/hostmonster-review",
            anchortext: "Hostmonster Review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 5
        },



        {
            link: "https://www.findbestwebhosting.com/web-hosting-blog/index.php/cloudways-managed-hosting-review",
            anchortext: "Cloudways Review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 5
        },


        {
            link: "/hostgator-review",
            anchortext: "Hostgator Review",
            recommend: 5,
            supportrating: 4,
            uptimerating: 4
        },



        {
            link: "/hostmonster-review",
            anchortext: "Hostmonster Review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 5
        },



        {
            link: "/godaddy-review",
            anchortext: "Godaddy  Review",
            recommend: 5,
            supportrating: 4,
            uptimerating: 4
        },


        {
            link: "/ipage-review",
            anchortext: "iPage Review",
            recommend: 5,
            supportrating: 4,
            uptimerating: 4
        },


        {
            link: "/greengeeks-review",
            anchortext: "Greengeeks Review",
            recommend: 5,
            supportrating: 4,
            uptimerating: 4
        },


        {
            link: "https://www.findbestwebhosting.com/web-hosting-blog/index.php/hostinger-review", anchortext: "Hostinger Review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 4
        },


        {
            link: "https://www.findbestwebhosting.com/web-hosting-blog/index.php/a2-hosting-detailed-review-speed-hosting-and-more",
            anchortext: "A2 Hosting Review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 5
        },


        {
            link: "https://www.findbestwebhosting.com/web-hosting-blog/index.php/pressidium-review-managed-wordpress-hosting",
            anchortext: "Pressidium Review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 5
        },


        {
            link: "/eleven2-review",
            anchortext: "Eleven2  Review",
            recommend: 5,
            supportrating: 4,
            uptimerating: 4
        },


        {
            link: "/site5-hosting-review",
            anchortext: "Site5 Review",
            recommend: 5,
            supportrating: 4,
            uptimerating: 4
        },

        {
            link: "/fatcow-review",
            anchortext: "Fatcow  Review",
            recommend: 5,
            supportrating: 4,
            uptimerating: 4
        },


        {
            link: "/hostpapa-review",
            anchortext: "Hostpapa Review",
            recommend: 5,
            supportrating: 4,
            uptimerating: 4
        },


        {
            link: "/justhost-review",
            anchortext: "JustHost Review",
            recommend: 5,
            supportrating: 4,
            uptimerating: 4
        },


        {
            link: "/web-hosting-uk-review",
            anchortext: "Web Hosting UK Review",
            recommend: 5,
            supportrating: 4,
            uptimerating: 4
        },


        {
            link: "/eboundhost-review",
            anchortext: "eBoundHost Review",
            recommend: 5,
            supportrating: 4,
            uptimerating: 4
        },


        {
            link: "/ix-web-hosting-review",
            anchortext: "IX Web Hosting Review",
            recommend: 5,
            supportrating: 3,
            uptimerating: 4
        },


        {
            link: "/applied-innovations-review",
            anchortext: "Applied Innovations Review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 5
        },


        {
            link: "/server-intellect-review",
            anchortext: "Server Intellect Review",
            recommend: 5,
            supportrating: 5,
            uptimerating: 5
        },




    ];

    return (


        <React.Fragment key={'parent'}>
            <table>
                <tbody>
                    <tr>

                        <td>Company</td>
 
                        <td>Support Rating</td>
                        <td>uptime Rating</td>

                    </tr>

                    <React.Fragment key={'menueviews'}>


                        {

                            ReviewMenuItems.map((reviewitem, index) => {
                                //const recommend = "/imagesfbwh/thumbs_up5.png";
                                const custsupport = "/imagesfbwh/" + reviewitem.supportrating + "star.gif"
                                const uptime = "/imagesfbwh/" + reviewitem.uptimerating + "star.gif"
                                //console.log("custsupport=",custsupport)
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Link target="new" to={`${reviewitem.link}`}>
                                                {reviewitem.anchortext} <br /> <br />
                                            </Link>
                                        </td>
                                           <td>
                                            <img src={`${custsupport}`} alt="" />
                                        </td>
                                        <td>
                                            <img src={`${uptime}`} alt="" />
                                        </td>
                                    </tr>

                                )
                            }




                            )


                        }

                    </React.Fragment>

                    {/*    <React.Fragment key={'articleslist'}>


                        {reviewslist.map((node, index) => {
                            //console.log("node=", node)

                            const recommend = node.node.recommend.replace("images", "imagesfbwh")
                            const custsupport = node.node.custsupport.replace("images", "imagesfbwh")
                            const uptime = node.node.uptime.replace("images", "imagesfbwh")

                            return (
                                <React.Fragment key={index}>
                                    <tr key={index}>
                                        <td>
                                            <Link to={`/${node.node.reviewtitle}`}>
                                                {node.node.companytitle} <br /> <br />
                                            </Link>
                                        </td>
                                        <td>
                                            <img src={`${recommend}`} alt="" />
                                        </td>
                                        <td>
                                            <img src={`${custsupport}`} alt="" />
                                        </td>
                                        <td>
                                            <img src={`${uptime}`} alt="" />
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )
                        })}


                    </React.Fragment> */}
                </tbody>
            </table>
        </React.Fragment>
    )
}

export default ReviewsSummary
